import React, { useState } from "react";
import { Col, Row, Checkbox, Form, Input, message } from "antd";
import * as AuthApi from "../services/api/auth";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../components/Button/Button";
import AuthHeader from "../components/Dashboard/AuthHeader";
import { toast } from "react-toastify";

function ForgetPassword() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const onFinish = async (credentials) => {

    try {
      console.log('credentials', credentials);
      const { data, response: { status } } = await AuthApi.forgetPassword(credentials);
      localStorage.setItem("forgotPassCredentials", JSON.stringify(credentials.email));
      if (status === 200) {
        toast.success(data.message);
        navigate("/confirm-password");
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      <div className="login">
        <AuthHeader />
        <div className="forget-section">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col>
              <Form
                className="login-form"
                name="basic"
                onFinish={onFinish}
                layout="vertical"
                style={{ marginTop: "0px" }}
              >
                <div className="input-field">
                  <h4 style={{ color: "white" }}>Forget Password</h4>
                  <Form.Item
                    label={<label style={{ color: "White" }}>Email:</label>}
                    name="email"
                    style={{ color: "white" }}
                    rules={[
                      { required: true, message: "Please input your email!" },
                      {
                        type: "email",
                        message: "Please enter valid email",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <DefaultButton
                    className="colored_btn"
                    title={"Submit"}
                    loading={loader}
                    htmlType={"submit"}
                  />
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default ForgetPassword;
