/** @format */

import axios from "axios";
// import Cookies from 'js-cookie';
// https://9t7b0bh9k6.execute-api.us-east-1.amazonaws.com/dev
//Do not add "/" at the API_URL end
export const API_URL ="https://8nfupbra34.execute-api.ap-south-1.amazonaws.com/prod/api";  // For India
// 
// export const API_URL = "http://localhost:4000/api";
// 
export async function axiosApi({
  endpoint,
  method = "GET",
  bodyData,
  contentType = null,
}) {
  try {
    const result = await axios.request({
      url: `${API_URL}/${endpoint}`,
      method,
      ...(method !== "DELETE" && {
        data:
          method === "PUT" || method === "POST" || method === "PATCH"
            ? bodyData
            : null,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": contentType ? contentType : "application/json",
      },
      // withCredentials: true,
    });

    const { data, ...response } = result;
    return { data, response };
  } catch (error) {
    const { response, message } = error;
    if (response && response.status === 403) {
      window.location.href = "/auth/login";
      // Cookies.remove('userInfo');
    } else if (error.request) {
      console.log("The request was made but no response was received");
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("message", message);
    }
    console.log("message", message);
    throw error;
  }
}
