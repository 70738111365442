/** @format */

import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import * as AuthApi from "../services/api/auth";
import DefaultButton from "../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import AuthHeader from "../components/Dashboard/AuthHeader";
import { toast } from "react-toastify";

function Login() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const login = async (credentials) => {
    try {
      setLoader(true);
      const {
        data: { data },
        response: { status },
      } = await AuthApi.loginApi(credentials);

      if (status === 200) {
        localStorage.setItem("userInfo", JSON.stringify(data));
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error?.response?.data?.message);
    }
    finally {
      setLoader(false);
    }
  };

  const checkUserToken = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const userToken = userInfo?.access_token;

    if (userToken) {
      return navigate("/dashboard");
    }
  };

  useEffect(() => {
    checkUserToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="login">
        <AuthHeader />
        <div className="Main-field">
          <Form
            className="login-form"
            name="basic"
            onFinish={login}
            layout="vertical"
          >
            <div className="input-field">
              <h4 style={{ color: "white" }}>Login</h4>
              <Form.Item
                label={<label style={{ color: "#ffff" }}>E-mail:</label>}
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  {
                    type: "email",
                    message: "Please enter valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={<label style={{ color: "#ffff" }}>Password:</label>}
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input type="password" />
              </Form.Item>
              <Link to="/forget-password">
                <h5>Forgot your password?</h5>
              </Link>

              <Form.Item
                valuePropName="Unchecked"
                wrapperCol={{ offset: 8, span: 16 }}
              >
                {/* <Checkbox style={{ color: "#ffff" }}>
                  Keep me signed in
                </Checkbox> */}
              </Form.Item>
              <DefaultButton
                className="colored_btn"
                title={"login"}
                loading={loader}
                htmlType={"submit"}
              />
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
export default Login;
